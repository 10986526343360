import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Axios from "axios";
import { ExternalLinkIcon } from "@heroicons/react/solid";

function IsrSummaryComp(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [telstracommCount, setTelstracommCount] = useState(0);
  const [optuscommCount, setOptuscommCount] = useState(0);

  useEffect(() => {
    let isMounted = true; // Add a mounted flag

    const getTelstracommCount = (data) => {
      let count = 0; //including KEPK, SSIM and DSIM
      data.forEach((c) => {
        if (
          c.status === "existing" &&
          c.host.name.toLowerCase() !== "ga" &&
          c.host.name.toLowerCase() !== "corsnet-nsw"
        ) {
          if (
            c.comms[0] !== undefined &&
            c.comms[0].name.toLowerCase().includes("telstra")
          ) {
            count++;
          }
        }
      });
      if (isMounted) {
        setTelstracommCount(count);
      }
    };

    const getOptuscommCount = (data) => {
      let count = 0; // there are 2 optus comm for testing (Lpoc, Opoc and DSIM)
      data.forEach((c) => {
        if (
          c.status === "existing" &&
          c.host.name.toLowerCase() !== "ga" &&
          c.host.name.toLowerCase() !== "corsnet-nsw"
        ) {
          if (
            c.comms[0] !== undefined &&
            c.comms[0].name.toLowerCase().includes("optus")
          ) {
            count++;
          }
        }
      });
      if (isMounted) {
        setOptuscommCount(count);
      }
    };

    const computePrimaryComms = async () => {
      try {
        // const res = await Axios.get("https://cms.vicpos.com.au/cors?_limit=-1");
        if (props.primaryCommsData.length > 0) {
          getTelstracommCount(props.primaryCommsData);
          getOptuscommCount(props.primaryCommsData);
          if (isMounted) {
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching data: ", error);
        }
      }
    };
    computePrimaryComms();
    return () => {
      isMounted = false; // Cleanup function to set the flag to false
    };
  }, [props.primaryCommsData]); // Add props.primaryCommsData as a dependency

  return (
    <div>
      {isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (
        <>
          <br />
          <Button variant="primary" disabled={true} style={{ opacity: "100" }}>
            Telstra{" "}
            <span className="badge badge-danger">
              {props.telstraCommCount} / {telstracommCount}
            </span>
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;
          <Button variant="primary" disabled={true} style={{ opacity: "100" }}>
            Optus{" "}
            <span className="badge badge-danger">
              {props.optusCommCount} / {optuscommCount}
            </span>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="primary" disabled={true} style={{ opacity: "100" }}>
            Unknown{" "}
            <span className="badge badge-danger">{props.unknowCommCount}</span>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <a
            className="btn btn-info"
            href="https://grafana.leviandco.com.au/d/aees2761fzd34b/site-sim-provider?orgId=1&from=now-6h&to=now&timezone=browser"
            target="_blank"
            rel="noreferrer"
          >
            Connection History <ExternalLinkIcon style={{ height: "20px" }} />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            className="btn btn-info"
            href="https://grafana.leviandco.com.au/d/aefkmb0t9gp34f/site-comms-signal?orgId=1&from=now-3h&to=now&timezone=browser"
            target="_blank"
            rel="noreferrer"
          >
            Signal History <ExternalLinkIcon style={{ height: "20px" }} />
          </a>
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default IsrSummaryComp;
