import React, { useState, useEffect, useReducer, useRef } from "react";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
// import cellEditFactory from "react-bootstrap-table2-editor";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { formatDistanceStrict } from "date-fns";
import IsrSummaryComp from "./IsrSummaryComp";

const dataReducer = (filteredData, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return filteredData;
  }
};

const PubIsrManager = () => {
  const url = "https://s3api.vicpos.com.au";
  const secret =
    "bc1c0194cf84447a8a18e2a56e5178f8d5879a379481d6d7d74d09b812876d4d";
  const [isrStatus, setIsrStatus] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const keyword = useRef("");
  const [filteredData, dispatch] = useReducer(dataReducer, []);
  const user = localStorage.getItem("username");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [telstraCommCount, setTelstraCommCount] = useState(0);
  const [optusCommCount, setOptusCommCount] = useState(0);
  const [unknowCommCount, setUnknowCommCount] = useState(0);
  const [primaryCommsData, setPrimaryCommsData] = useState([]);

  const searchHandler = (st) => {
    const newList = isrStatus.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(st.toLowerCase());
    });
    dispatch({ type: "keywords", payload: newList });
    handleCountCalc(newList);
  };

  const getCarrierLabel = (active_profile) => {
    if (active_profile.includes("telstra")) {
      return <span className="badge badge-primary">Telstra</span>;
    } else if (active_profile.includes("yesbusiness")) {
      return <span className="badge badge-warning">Optus</span>;
    } else {
      return <span className="badge badge-info">Unknown</span>;
    }
  };

  const handleCountCalc = (data) => {
    let telstraCount = data.filter((d) =>
      d.active_profile.toLowerCase().includes("telstra")
    ).length;
    let optusCount = data.filter((d) =>
      d.active_profile.toLowerCase().includes("yesbusiness")
    ).length;
    let unknownCount = data.filter((d) =>
      d.active_profile.toLowerCase().includes("unknown")
    ).length;
    setTelstraCommCount(telstraCount);
    setOptusCommCount(optusCount);
    setUnknowCommCount(unknownCount);
  };

  const handleFailoverClick = async (
    device_name,
    ip_address,
    status,
    active_profile
  ) => {
    const data = await axios
      .get(
        url +
          "/set_isr_failover?device_name=" +
          device_name +
          "&ip_address=" +
          ip_address +
          "&status=" +
          status +
          "&active_profile=" +
          active_profile +
          "&secret=" +
          secret
      )
      .then((res) => console.log(res.data.message));
  };

  const getStrapiData = async () => {
    const data = await axios
      .get("https://cms.vicpos.com.au/cors?_limit=-1")
      .then((response) => {
        setPrimaryCommsData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(url + "/get_isr_status")
      .then((response) => {
        // remove data which has active_profile as null
        response.data = response.data.filter((d) => d.active_profile !== null);
        setIsrStatus(response.data);
        dispatch({ type: "initial", payload: response.data });
        setIsLoading(false);
        handleCountCalc(response.data);
        getStrapiData();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      dataField: "active_profile",
      text: "Carrier",
      sort: true,
      formatter: (cell, row) => getCarrierLabel(row.active_profile),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "device_name",
      text: "Site",
      sort: true,
      formatter: (cell, row) => cell.substring(0, 4),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "rad_tech",
      text: "Tech",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "time_since",
      text: "Since",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => {
        const getTimeSince = (cell) => {
          const now = new Date();
          const utcNow = new Date(now.toUTCString());
          const utcCell = new Date(cell + "Z");
          const localTimeDifference = formatDistanceStrict(utcCell, utcNow);
          return localTimeDifference;
        }; // Parse the cell value as ISO date

        const timeDifference = getTimeSince(cell); // Fix: Pass cell instead of now
        let timed = timeDifference + " ago";
        return timed;
      },
    },
    {
      dataField: "time_value",
      text: "Ping (ms)",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
  ];

  return (
    <Container>
      <h1>ISR Manager</h1>
      <br />
      <TextField
        type="text"
        placeholder="Filter by Site name..."
        inputRef={keyword}
        onChange={() => {
          searchHandler(keyword.current.value);
        }}
        className="form-control w-100"
      />
      {isLoading ? (
        <>
          <Spinner animation="border" variant="primary" /> Loading...
        </>
      ) : (
        <>
          <IsrSummaryComp
            telstraCommCount={telstraCommCount}
            optusCommCount={optusCommCount}
            unknowCommCount={unknowCommCount}
            primaryCommsData={primaryCommsData}
          />
          <BootstrapTable
            keyField="id"
            data={filteredData}
            columns={columns}
            striped
            hover
            responsive
            style={{ width: "80%", margin: "0 auto" }}
          />
        </>
      )}
    </Container>
  );
};
// };

export default PubIsrManager;
