import React, { useState, useEffect, useReducer, useRef } from "react";
import axios from "axios";
import { Container, Spinner, Tabs, Tab } from "react-bootstrap";
import { Hidden, TextField } from "@material-ui/core";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const dataReducer = (filteredData, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return filteredData;
  }
};

const SiteStatus = () => {
  const url = "https://s3api.vicpos.com.au";
  const secret =
    "bc1c0194cf84447a8a18e2a56e5178f8d5879a379481d6d7d74d09b812876d4d";
  const [siteData, setSiteData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const keyword = useRef("");
  const [filteredData, dispatch] = useReducer(dataReducer, []);
  const [existingSite, setExistingSite] = useState([]);
  const [poiSite, setPoiSite] = useState([]);
  const [decommmssionedSite, setDecommissionedSite] = useState([]);
  const [constructingSite, setConstructingSite] = useState([]);
  const [planningSite, setPlanningSite] = useState([]);
  const [d_tab, setD_tab] = useState("existingSite");

  const searchHandler = (st) => {
    console.log(st);
    const newList = siteData.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(st.toLowerCase());
    });
    console.log(newList);
    dispatch({ type: "keywords", payload: newList });
  };

  useEffect(() => {
    axios
      .get(url + "/get_site_status")
      .then((response) => {
        setSiteData(response.data);
        dispatch({ type: "initial", payload: response.data });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const existing = filteredData.filter(
      (site) => site.site_status === "existing"
    );
    const poi = filteredData.filter((site) => site.site_status === "POI");
    const decommissioned = filteredData.filter(
      (site) => site.site_status === "Decomissioned"
    );
    const constructing = filteredData.filter(
      (site) => site.site_status === "constructing"
    );
    const planning = filteredData.filter(
      (site) => site.site_status === "planning"
    );

    setExistingSite(existing);
    setPoiSite(poi);
    setDecommissionedSite(decommissioned);
    setConstructingSite(constructing);
    setPlanningSite(planning);
  }, [filteredData]);

  const existing_columns = [
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "site_code",
      text: "Code",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => {
        let style = { backgroundColor: "#39b54e", color: "white" }; // Default color

        if (row.site_host) {
          if (row.site_host.toLowerCase() === "corsnet-nsw") {
            style = { backgroundColor: "#3aa6d9", color: "white" };
          } else if (row.site_host.toLowerCase() === "ga") {
            style = { backgroundColor: "#ffbe56", color: "white" };
          }
        }

        return (
          <button className="btn" style={style}>
            {cell}
          </button>
        );
      },
    },
    {
      dataField: "site_status",
      text: "Status",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    // {
    //   dataField: "commission_date",
    //   text: "Last FW Update",
    //   sort: true,
    //   sortCaret: (order, column) => {
    //     if (order === "asc") return <IoIosArrowUp />;
    //     if (order === "desc") return <IoIosArrowDown />;
    //     return null;
    //   },
    // },
    {
      dataField: "installedDate",
      text: "Site Inst Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
  ];
  const columns = [
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "site_code",
      text: "Code",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => {
        let style = { backgroundColor: "#39b54e", color: "white" }; // Default color

        if (row.site_host) {
          if (row.site_host.toLowerCase() === "corsnet-nsw") {
            style = { backgroundColor: "#3aa6d9", color: "white" };
          } else if (row.site_host.toLowerCase() === "ga") {
            style = { backgroundColor: "#ffbe56", color: "white" };
          }
        }

        return (
          <button className="btn" style={style}>
            {cell}
          </button>
        );
      },
    },
    {
      dataField: "site_status",
      text: "Status",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
  ];
  const decommissioned_columns = [
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "site_code",
      text: "Code",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => {
        let style = { backgroundColor: "#39b54e", color: "white" }; // Default color

        if (row.site_host) {
          if (row.site_host.toLowerCase() === "corsnet-nsw") {
            style = { backgroundColor: "#3aa6d9", color: "white" };
          } else if (row.site_host.toLowerCase() === "ga") {
            style = { backgroundColor: "#ffbe56", color: "white" };
          }
        }

        return (
          <button className="btn" style={style}>
            {cell}
          </button>
        );
      },
    },
    {
      dataField: "site_status",
      text: "Status",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },

    {
      dataField: "decommission_date",
      text: "Decommission Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "installedDate",
      text: "Site Inst Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
  ];

  return (
    <Container>
      <h1>Site Installation and Decommission Info</h1>
      <br />
      <TextField
        type="text"
        placeholder="Filter by Site name..."
        inputRef={keyword}
        onChange={() => {
          searchHandler(keyword.current.value);
        }}
        className="form-control w-100"
      />
      <br />
      <br />
      {isLoading ? (
        <>
          <Spinner animation="border" variant="primary" /> Loading...
        </>
      ) : (
        <>
          <Tabs defaultActiveKey={d_tab}>
            <Tab eventKey="existingSite" title="Existing Site">
              <BootstrapTable
                keyField="id"
                data={existingSite}
                columns={existing_columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Tab>
            <Tab eventKey="decommissionSite" title="Decommissioned Site">
              <BootstrapTable
                keyField="id"
                data={decommmssionedSite}
                columns={decommissioned_columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Tab>
            <Tab eventKey="constructingSite" title="Constructing Site">
              <BootstrapTable
                keyField="id"
                data={constructingSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Tab>
            <Tab eventKey="planningSite" title="Planning Site">
              <BootstrapTable
                keyField="id"
                data={planningSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Tab>
            <Tab eventKey="poiSite" title="Point of Interest Site">
              <BootstrapTable
                keyField="id"
                data={poiSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Tab>
          </Tabs>
        </>
      )}
    </Container>
  );
};
// };

export default SiteStatus;
