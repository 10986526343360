import React, { useState, useEffect, useReducer, useRef } from "react";
import axios from "axios";
import { Button, Spinner, Tabs, Tab } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import moment from "moment-timezone";
import "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format, formatDistanceStrict } from "date-fns";
import IsrSummaryComp from "./IsrSummaryComp";

const dataReducer = (filteredData, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return filteredData;
  }
};

const IsrManager = () => {
  const url = "https://s3api.vicpos.com.au";
  const secret =
    "bc1c0194cf84447a8a18e2a56e5178f8d5879a379481d6d7d74d09b812876d4d";
  const [isrStatus, setIsrStatus] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const keyword = useRef("");
  const [filteredData, dispatch] = useReducer(dataReducer, []);
  const user = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [d_tab, setD_tab] = useState("isrOverview");
  const [failoverData, setFailoverData] = useState([]);
  const [reloadData, setReloadData] = useState([]);
  const [notifyMessage, setNotifyMessage] = useState([]);

  const [telstraCommCount, setTelstraCommCount] = useState(0);
  const [optusCommCount, setOptusCommCount] = useState(0);
  const [unknowCommCount, setUnknowCommCount] = useState(0);
  const [primaryCommsData, setPrimaryCommsData] = useState([]);
  const [siteComms, setSiteComms] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const dateFormatter = (cell, row) => {
    const formattedDate = moment
      .utc(cell)
      .tz("Australia/Melbourne")
      .format("l ddd, HH:mm");
    return <span>{formattedDate}</span>;
  };

  const notifyDataInfo = (message) =>
    toast.success(
      <div>{message}</div>,

      {
        position: toast.POSITION.TOP_RIGHT,
        containerId: "A",
      }
    );

  const searchHandler = (st) => {
    const newList = combinedData.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(st.toLowerCase());
    });
    handleCountCalc(newList);
    dispatch({ type: "keywords", payload: newList });
  };

  const getCarrierLabel = (active_profile) => {
    if (active_profile.includes("telstra")) {
      return <span className="badge badge-primary">Telstra</span>;
    } else if (active_profile.includes("yesbusiness")) {
      return <span className="badge badge-warning">Optus</span>;
    } else {
      return <span className="badge badge-info">Unknown</span>;
    }
  };

  const handleFailoverClick = async (
    device_name,
    ip_address,
    status,
    active_profile
  ) => {
    try {
      const response = await axios.get(
        url +
          "/set_isr_failover?device_name=" +
          device_name +
          "&ip_address=" +
          ip_address +
          "&status=" +
          status +
          "&active_profile=" +
          active_profile +
          "&secret=" +
          secret +
          "&requester=" +
          localStorage.username
      );

      const message = response.data.message;

      getReloadData();
      getFailoverData();
      notifyDataInfo(message);
    } catch (error) {
      console.error("Error while making API call:", error);
    }
  };

  const handleReloadClick = async (
    device_name,
    ip_address,
    status,
    active_profile
  ) => {
    console.log(localStorage.username);
    try {
      const response = await axios.get(
        url +
          "/set_isr_reload?device_name=" +
          device_name +
          "&ip_address=" +
          ip_address +
          "&status=" +
          status +
          "&active_profile=" +
          active_profile +
          "&secret=" +
          secret +
          "&requester=" +
          localStorage.username
      );

      const message = response.data.message;

      getReloadData();
      getFailoverData();
      notifyDataInfo(message);
    } catch (error) {
      console.error("Error while making API call:", error);
    }
  };

  const getFailoverData = async (req, res) => {
    const data = await axios
      .get(url + "/check_isr_failover?secret=" + secret)
      .then((res) => setFailoverData(res.data));
  };
  const getReloadData = async (req, res) => {
    const data = await axios
      .get(url + "/check_isr_reload?secret=" + secret)
      .then((res) => setReloadData(res.data));
  };

  const handleCountCalc = (data) => {
    let telstraCount = data.filter((d) =>
      d.active_profile.toLowerCase().includes("telstra")
    ).length;
    let optusCount = data.filter((d) =>
      d.active_profile.toLowerCase().includes("yesbusiness")
    ).length;
    let unknownCount = data.filter((d) =>
      d.active_profile.toLowerCase().includes("unknown")
    ).length;
    setTelstraCommCount(telstraCount);
    setOptusCommCount(optusCount);
    setUnknowCommCount(unknownCount);
  };

  const getIsrDataS3Api = async () => {
    try {
      await axios.get(url + "/get_isr_status_v2").then((response) => {
        // remove data row with active_profile = null
        response.data = response.data.filter((d) => d.active_profile !== null);
        // console.log(response.data);
        setIsrStatus(response.data);
        dispatch({ type: "initial", payload: response.data });
        return response.data;
      });
    } catch (error) {
      console.error("Error while making API call:", error);
    }
  };

  const getStrapiData = async () => {
    // try {
    const response = await axios
      .get("https://cms.vicpos.com.au/cors?_limit=-1")
      .then((response) => {
        setPrimaryCommsData(response.data);
        let siteComms = [];
        response.data.forEach((d) => {
          if (
            d.status === "existing" &&
            d.host.name.toLowerCase() !== "ga" &&
            d.host.name.toLowerCase() !== "corsnet-nsw"
          ) {
            if (
              d.comms[0] !== undefined &&
              d.comms[0].name.toLowerCase().includes("telstra")
            ) {
              // push the json object lite {site: "site_code", primary: "telstra"}
              siteComms.push({ site: d.code, primary: "Telstra" });
            } else if (
              d.comms[0] !== undefined &&
              d.comms[0].name.toLowerCase().includes("optus")
            ) {
              siteComms.push({ site: d.code, primary: "Optus" });
            } else {
              siteComms.push({ site: d.code, primary: "Unknown" });
            }
          }
        });
        setSiteComms(siteComms);
        return siteComms; // return the data
      });
  };

  const mergeObjData = () => {
    let mergedData = isrStatus.map((d) => {
      let siteComm = siteComms.find(
        (sc) => sc.site === d.device_name.substring(0, 4)
      );
      return { ...d, primary: siteComm ? siteComm.primary : "unknown" };
    });
    setCombinedData(mergedData);
    dispatch({ type: "initial", payload: mergedData });
    if (isrStatus.length > 0 && siteComms.length > 0) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getIsrDataS3Api();
    getStrapiData();
    getFailoverData();
    getReloadData();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    mergeObjData(isrStatus, siteComms);
    handleCountCalc(isrStatus);
  }, [isrStatus, siteComms]);

  useEffect(() => {}, []);

  const handleActionClick = async (
    device_name,
    ip_address,
    status,
    active_profile,
    action
  ) => {
    let response;
    let actionMessage;
    try {
      device_name = encodeURIComponent(device_name);
      active_profile = encodeURIComponent(active_profile);
      status = encodeURIComponent(status);

      if (action === "failover") {
        response = await axios.post(
          url +
            "/process_isr_failover?device_name=" +
            device_name +
            "&ip_address=" +
            ip_address +
            "&status=" +
            status +
            "&secret=" +
            secret,
          {}
        );
        actionMessage = " Failover cancelled";
      } else if (action === "reload") {
        response = await axios.post(
          url +
            "/process_isr_reload?device_name=" +
            device_name +
            "&ip_address=" +
            ip_address +
            "&status=" +
            status +
            "&secret=" +
            secret,
          {}
        );
        actionMessage = " Reload cancelled";
      }
    } catch (error) {
      console.error("Error while making API call:", error);
    }
    notifyDataInfo(response.data.message + actionMessage);
    getFailoverData();
    getReloadData();
  };

  function generateColumns(actionType) {
    const actionButtonLabel = actionType === "failover" ? "Failover" : "Reload";

    return [
      {
        dataField: "active_profile",
        text: "Carrier",
        sort: true,
        formatter: (cell, row) => getCarrierLabel(row.active_profile),
        sortCaret: (order, column) => {
          if (order === "asc") return <IoIosArrowUp />;
          if (order === "desc") return <IoIosArrowDown />;
          return null;
        },
      },
      {
        dataField: "device_name",
        text: "Device",
        sort: true,
        formatter: (cell, row) => cell.substring(0, 4),
        sortCaret: (order, column) => {
          if (order === "asc") return <IoIosArrowUp />;
          if (order === "desc") return <IoIosArrowDown />;
          return null;
        },
      },
      {
        dataField: "ip_address",
        text: "IP",
        sort: true,
        sortCaret: (order, column) => {
          if (order === "asc") return <IoIosArrowUp />;
          if (order === "desc") return <IoIosArrowDown />;
          return null;
        },
      },
      {
        dataField: "timestamp",
        text: "Scheduled",
        sort: true,
        sortCaret: (order, column) => {
          if (order === "asc") return <IoIosArrowUp />;
          if (order === "desc") return <IoIosArrowDown />;
          return null;
        },
        formatter: dateFormatter, // Use the custom formatter
      },
      {
        dataField: "requester",
        text: "Requester",
        sort: true,
        sortCaret: (order, column) => {
          if (order === "asc") return <IoIosArrowUp />;
          if (order === "desc") return <IoIosArrowDown />;
          return null;
        },
      },
      {
        text: "Action",
        formatter: (cell, row) => (
          <Button
            variant="danger"
            onClick={() =>
              handleActionClick(
                row.device_name,
                row.ip_address,
                row.status,
                row.active_profile,
                actionType
              )
            }
          >
            Cancel
          </Button>
        ),
      },
    ];
  }

  const columns = [
    {
      dataField: "active_profile",
      text: "Carrier",
      sort: true,
      formatter: (cell, row) => getCarrierLabel(row.active_profile),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "primary",
      text: "Primary",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "device_name",
      text: "Site",
      sort: true,
      formatter: (cell, row) => cell.substring(0, 4),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "ip_address",
      text: "IP Address",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "rad_tech",
      text: "Tech",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "time_since",
      text: "Since",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => {
        const getTimeSince = (cell) => {
          const now = new Date();
          const utcNow = new Date(now.toUTCString());
          const utcCell = new Date(cell + "Z");
          const localTimeDifference = formatDistanceStrict(utcCell, utcNow);
          return localTimeDifference;
        }; // Parse the cell value as ISO date

        const timeDifference = getTimeSince(cell); // Fix: Pass cell instead of now
        let timed = timeDifference + " ago";
        return timed;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "time_value",
      text: "Ping (ms)",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },

    {
      text: "Failover",
      sort: true,
      sortValue: (cell, row) => (row.active_profile.endsWith("2") ? 1 : 0),
      formatter: (cell, row) => (
        <Button
          variant={row.active_profile.endsWith("2") ? "success" : "warning"}
          onClick={() =>
            handleFailoverClick(
              row.device_name,
              row.ip_address,
              row.status,
              row.active_profile
            )
          }
          disabled={!row.count_of_profile.endsWith("2")}
        >
          Failover
        </Button>
      ),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      text: "Reload",
      sort: true,
      sortValue: (cell, row) => (row.active_profile.endsWith("2") ? 1 : 0),
      formatter: (cell, row) => (
        <Button
          variant={"danger"}
          onClick={() =>
            handleReloadClick(
              row.device_name,
              row.ip_address,
              row.status,
              row.active_profile
            )
          }
        >
          Reload
        </Button>
      ),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    // Add more columns as needed
  ];
  const columns_signal_strength = [
    {
      dataField: "active_profile",
      text: "Carrier",
      sort: true,
      formatter: (cell, row) => getCarrierLabel(row.active_profile),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "device_name",
      text: "Site",
      sort: true,
      formatter: (cell, row) => cell.substring(0, 4),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "cellular_antenna",
      text: "Antenna",
      sort: true,
      // formatter: (cell, row) => cell.substring(0, 4),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "rssi",
      text: "RSSI (dBm)",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // conditional background color
      style: (cell) => {
        if (cell <= -95) {
          return { backgroundColor: "#a4181a" };
        } else if (cell <= -85 && cell > -95) {
          return { backgroundColor: "#e12428" };
        } else if (cell <= -75 && cell > -85) {
          return { backgroundColor: "#f0bc3c" };
        } else if (cell > -75 && cell <= -65) {
          return { backgroundColor: "#f8fc4f" };
        } else if (cell > -65) {
          return { backgroundColor: "#73cd64" };
        } else {
          // return { backgroundColor: "#73cd64" };
          return { backgroundColor: "#a3171a" };
        }
      },
    },
    {
      dataField: "rsrp",
      text: "RSRP (dBm)",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // conditional background color
      style: (cell) => {
        if (cell <= -100) {
          return { backgroundColor: "#a3171a" };
        } else if (cell <= -90) {
          return { backgroundColor: "#e22328" };
        } else if (cell <= -80) {
          return { backgroundColor: "#deae38" };
        } else if (cell > -80) {
          return { backgroundColor: "#73cc63" };
        } else {
          return { backgroundColor: "#a3171a" };
        }
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "rsrq",
      text: "RSRQ (dB)",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // conditional background color
      style: (cell) => {
        if (cell <= -20) {
          return { backgroundColor: "#a3171a" };
        } else if (cell <= -15 && cell > -20) {
          return { backgroundColor: "#f1bc3d" };
        } else if (cell <= -10 && cell > -15) {
          return { backgroundColor: "#f7fb4f" };
        } else if (cell > -10) {
          return { backgroundColor: "#73cc63" };
        } else {
          return { backgroundColor: "#a3171a" };
        }
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "snr",
      text: "SNR (dB)",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // conditional background color
      style: (cell) => {
        if (cell >= 20) {
          return { backgroundColor: "#73cc63" };
        } else if (cell > 13 && cell < 20) {
          return { backgroundColor: "#f7fb4f" };
        } else if (cell >= 0 && cell < 13) {
          return { backgroundColor: "#f1bc3d" };
        } else {
          return { backgroundColor: "#a3171a" };
        }
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },

    // Add more columns as needed
  ];

  const columns_detail = [
    {
      dataField: "active_profile",
      text: "Carrier",
      sort: true,
      formatter: (cell, row) => getCarrierLabel(row.active_profile),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      dataField: "device_name",
      text: "Site",
      sort: true,
      formatter: (cell, row) => cell.substring(0, 4),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },

    {
      dataField: "reload_reason",
      text: "Reload Reason",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "last_boot_up_time",
      text: "Boot time",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "processor_version",
      text: "Model",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "serial_number",
      text: "SN",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // headerClasses: "hide-on-mobile", // Show the header on mobile devices
      // classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "ios_version",
      text: "IOS",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },
    {
      dataField: "fw_version",
      text: "FW",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      headerClasses: "hide-on-mobile", // Show the header on mobile devices
      classes: "hide-on-mobile", // Hide the column on mobile devices
    },

    {
      text: "Failover",
      sort: true,
      sortValue: (cell, row) => (row.active_profile.endsWith("2") ? 1 : 0),
      formatter: (cell, row) => (
        <Button
          variant={row.active_profile.endsWith("2") ? "success" : "warning"}
          onClick={() =>
            handleFailoverClick(
              row.device_name,
              row.ip_address,
              row.status,
              row.active_profile
            )
          }
          disabled={!row.active_profile.endsWith("2")}
        >
          Failover
        </Button>
      ),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    {
      text: "Reload",
      sort: true,
      sortValue: (cell, row) => (row.active_profile.endsWith("2") ? 1 : 0),
      formatter: (cell, row) => (
        <Button
          variant={"danger"}
          onClick={() =>
            handleReloadClick(
              row.device_name,
              row.ip_address,
              row.status,
              row.active_profile
            )
          }
        >
          Reload
        </Button>
      ),
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
    },
    // Add more columns as needed
  ];

  if (
    user === "" ||
    token === "" ||
    token === undefined ||
    user === undefined ||
    user === null
  ) {
    return <Redirect from="/IsrManager" to="/Login?previousPage=IsrManager" />;
  } else {
    return (
      <div style={{ width: "95%", textAlign: "center", margin: "0 auto" }}>
        <ToastContainer
          enableMultiContainer
          containerId={"A"}
          position="top-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ textAlign: "left" }}
        />
        <h1>ISR Manager</h1>
        <br />
        <TextField
          type="text"
          placeholder="Filter by Site name..."
          inputRef={keyword}
          onChange={() => {
            searchHandler(keyword.current.value);
          }}
          className="form-control w-100"
        />
        {isLoading ? (
          <>
            <Spinner animation="border" variant="primary" /> Loading...
          </>
        ) : (
          <>
            <IsrSummaryComp
              telstraCommCount={telstraCommCount}
              optusCommCount={optusCommCount}
              unknowCommCount={unknowCommCount}
              primaryCommsData={primaryCommsData}
            />
            <Tabs defaultActiveKey={d_tab}>
              <Tab eventKey="isrOverview" title="ISR Overview">
                <BootstrapTable
                  keyField="id"
                  data={filteredData}
                  columns={columns}
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
              <Tab eventKey="isrDetails" title="ISR Details">
                <BootstrapTable
                  keyField="id"
                  data={filteredData}
                  columns={columns_detail}
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
              <Tab eventKey="isrSignalStrength" title="ISR Signal Strength">
                <BootstrapTable
                  keyField="id"
                  data={filteredData}
                  columns={columns_signal_strength}
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
              <Tab eventKey="isrFailover" title="Failover Schedular">
                <BootstrapTable
                  keyField="id"
                  data={failoverData}
                  columns={generateColumns("failover")} // Pass a flag to generate specific columns
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
              <Tab eventKey="isrReload" title="Reload Schedular">
                <BootstrapTable
                  keyField="id"
                  data={reloadData}
                  columns={generateColumns("reload")}
                  striped
                  hover
                  responsive
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    );
  }
};

export default IsrManager;
